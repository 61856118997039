<template>
  <section id="app-location">
    <div class="bg-app-location">
      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
    <div class="form-location text-start">
      <div class="header-form">
        <img src="@/assets/images/logo-alcaldia-acacias.png" alt="" width="150px">
        <span class="tag-acacias">#RecuperandoAcacias</span>
      </div>
      <section class="grid-flex-form">
        <div class="form-description">
          <h1 class="">
            ¡Conéctate a más de <span>+{{ this.locations.length }}</span> puntos Wi-Fi gratis en Acacias - Meta!
          </h1>
          <div class="description-app mt-md-5 mt-1">
            <p class="d-none d-md-block helper-description">
              Ingresa tu ubicación (Barrio, Carrera - Calle) y haz clic en "Buscar". Te mostraremos el punto Wi-Fi más cercano en el mapa y te guiaremos hasta allí 👇🏼 Pero espera, ¡hay más! 🚀 Si prefieres explorar, simplemente toca el punto Wi-Fi que te llame la atención en el mapa y traza la ruta con un solo clic en "¿Cómo llegar?" ¿Quieres descubrir el punto Wi-Fi más cercano a tu ubicación? ¡No hay problema! Simplemente dale clic al botón "Buscar el punto Wi-Fi más cercano". ¡Explora y conéctate en un instante! 🌐
            </p>
          </div>

          <!-- Mobile -->
          <div class="d-md-none d-block">
            <label for="" class="fw-bold mb-0 ms-2">Punto Wi-Fi más cercano 👇🏼</label>

            <button 
              class="btn-search my-1"
              @click="markNearestRoute">
              Buscar el punto Wi-Fi más cercano 
            </button>
          </div>

          

          <div class="search d-none d-md-block">
            <div class="form-template position-relative">

              <label for="" class="fw-bold mb-0">Punto Wi-Fi más cercano 👇🏼</label>

              <button 
                class="btn-search my-1"
                @click="markNearestRoute">
                Buscar el punto Wi-Fi más cercano 
              </button>


              <label for="" class="fw-bold mb-2 mt-3">Ingresa tu dirección</label>
              <div :class="['custom-select', { open: isOpen }]">
                <div class="custom-options" >
                  <div class="search-locations">
                    <i class="bi bi-search"></i>
                    <input 
                    type="text" 
                    placeholder="Busca tu punto Wi-Fi aquí" 
                    class="form-control"
                    v-model="search"
                    @keyup.enter="findLocation"
                    @input="validate">
                  </div>
                </div>
              </div>

              <div v-if="errorMessage" class="error-message mt-4 text-danger fs-6">
                {{ errorMessage }}
              </div>
              <button 
              class="btn-search"
              :disabled="!isSearchValid" 
              @click="findLocation"
              :class="{ 'disabled-button': !isSearchValid }">
                Buscar zonas wi-fi
              </button>

              <button 
              class="btn-resert"
              @click="resetMapAndClearSearch">
                Reiniciar
              </button>


              <span class="helper-notfound-zona">
                <b>¿No encuentras tu zona wifi?</b> Por favor, escríbenos <a href="https://www.facebook.com/AcaciasDigital" target="_blank">aquí</a> <br> #RecuperandoAcacias
              </span>
            </div>
          </div>

          <div class="form-template position-absolute d-block d-md-none">

              <div :class="['custom-select', { open: isOpen }]">
                <div class="custom-options" >
                  <div class="search-locations">
                    <i class="bi bi-search" @click='findLocation'></i>
                    <input 
                    type="text" 
                    placeholder="Busca el punto de zona Wi-Fi más cercano" 
                    class="form-control"
                    @blur='findLocation'
                    v-model="search" >
                  </div>
                </div>
              </div>
          </div>
        </div>

        <section class="h-100 position-relative">
          <div @click="resetMapAndClearSearch" class="reiniciar-mapa d-block d-md-none">
            <i class="bi bi-arrow-clockwise"></i>
          </div>
          <div id="map"></div>
        </section>
        
      </section>
    </div>
  </section>
</template>

<style>
@import "@/assets/css/app-location.css";
</style>

<script>
/* global google */
import axios from "axios";
import { GOOGLE_MAPS_API_KEY } from "@/config";
import marker_ico from "@/assets/images/marker_wifi.png";
import marker_here_ico from "@/assets/images/marker_here.png";

export default {
  data() {
    return {
      address: "",
      map: null,
      infoWindow: null,
      currentMarker: null,
      directionsService: null,
      directionsRenderer: null,
      currentLocationAvailable: false,
      directionsRenderers: [],
      routeColors: ["#FF0000", "#0000FF"],
      locations: [
        {
          lat: 3.993251,
          lng: -73.774377,
          title: "ZONA DIGITAL BACHUE",
          icon: marker_ico,
          dir: "Barrio Bachue, Carrera 33 Calle 17a",
        },
        {
          lat: 3.988778,
          lng: -73.768675,
          title: "ZONA DIGITAL LA CAROLINA",
          icon: marker_ico,
          dir: "Barrio La Carolina, Carrera 26 Calle 15",
        },
        {
          lat: 3.987788,
          lng: -73.756993,
          title: "ZONA DIGITAL LAS VEGAS",
          icon: marker_ico,
          dir: "Barrio Las Vegas, Carrera 14 Calle 16",
        },
        {
          lat: 3.984943,
          lng: -73.779293,
          title: "ZONA DIGITAL LAURELES",
          icon: marker_ico,
          dir: "Barrio Laureles, Calle 19",
        },
        {
          lat: 3.975664,
          lng: -73.762352,
          title: "ZONA DIGITAL MALECOM",
          icon: marker_ico,
          dir: "Parque Maleon Turisrtico ",
        },
        {
          lat: 3.976013,
          lng: -73.761177,
          title: "ZONA DIGITAL MALECOM",
          icon: marker_ico,
          dir: "Parque Maleon Turisrtico ",
        },
        {
          lat: 3.982732,
          lng: -73.769345,
          title: "ZONA DIGITAL VILLA DEL PRADO",
          icon: marker_ico,
          dir: "Barrio Villa del Prado, Carrera 26 Calle 7",
        },
        {
          lat: 3.990438,
          lng: -73.755931,
          title: "ZONA DIGITAL VILLA OLIMPICA PARQUE",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 3.997151,
          lng: -73.769971,
          title: "ZONA DIGITAL BARRIO ASOCIACION DE AMIGOS",
          icon: marker_ico,
          dir: "Barrio Asociación de amigos, Carrera 29 B Calle 16 ",
        },
        {
          lat: 3.988654,
          lng: -73.747613,
          title: "ZONA DIGITAL BARRIO LA HORMIGA",
          icon: marker_ico,
          dir: "Barrio La Hormiga, Calle 17",
        },
        {
          lat: 4.005577,
          lng: -73.773691,
          title: "ZONA DIGITAL PLAZA CENTENARIA",
          icon: marker_ico,
          dir: "Diagonal 15 Carrera 41",
        },
        {
          lat: 3.983371,
          lng: -73.773775,
          title: "ZONA DIGITAL PANORAMA",
          icon: marker_ico,
          dir: "Barrio Panorama, Carrera 31",
        },
        {
          lat: 3.9965,
          lng: -73.772161,
          title: "ZONA DIGITAL AIRES DE ACACIAS",
          icon: marker_ico,
          dir: "Barrio Aires de Acacías",
        },
        {
          lat: 3.993635,
          lng: -73.754948,
          title: "ZONA DIGITAL BAMBU",
          icon: marker_ico,
          dir: "Barrio Bambu, Carrera 12 A 11 ",
        },
        {
          lat: 3.993089,
          lng: -73.77959,
          title: "ZONA DIGITAL CIMARRON",
          icon: marker_ico,
          dir: "Barrio Cimarron, Carrera 45 B Calle 17",
        },
        {
          lat: 3.982676,
          lng: -73.763111,
          title: "ZONA DIGITAL COLISEO LAS FERIAS",
          icon: marker_ico,
          dir: "Carrera 18 Calle 10",
        },
        {
          lat: 3.998723,
          lng: -73.758045,
          title: "ZONA DIGITAL CONSTRUCTOR",
          icon: marker_ico,
          dir: "Barrio Constructor, Carrera 17 Calle 28",
        },
        {
          lat: 4.00109,
          lng: -73.77518,
          title: "ZONA DIGITAL COVICOM",
          icon: marker_ico,
          dir: "Carrera 13 Calle 18",
        },
        {
          lat: 3.98195,
          lng: -73.780263,
          title: "ZONA DIGITAL EL PLAYON",
          icon: marker_ico,
          dir: "Barrio El Playon, Carrera 40 Calle 7A ",
        },
        {
          lat: 3.996897,
          lng: -73.761702,
          title: "ZONA DIGITAL EL TREBOL",
          icon: marker_ico,
          dir: "Barrio El Trebol Carrera 20 Calle 23A",
        },
        {
          lat: 3.997459,
          lng: -73.759104,
          title: "ZONA DIGITAL GUARATARA",
          icon: marker_ico,
          dir: "Barrio Guaratara, Carrera 18 Calle 25",
        },
        {
          lat: 3.9867,
          lng: -73.777958,
          title: "ZONA DIGITAL INDEPENDENCIA ET 1",
          icon: marker_ico,
          dir: "Barrio Independencia ET 1 Carrera 36 Diagonal 12A",
        },
        {
          lat: 3.987385,
          lng: -73.779325,
          title: "ZONA DIGITAL NUEVO MILENIO",
          icon: marker_ico,
          dir: "Barrio Nuevo Milenio, Carrera 39A Diagonal 12 ",
        },
        {
          lat: 3.996262,
          lng: -73.755999,
          title: "ZONA DIGITAL LA ESPERANZA",
          icon: marker_ico,
          dir: "Barrio La Esperanza, Carrera 14 Calle 25A ",
        },
        {
          lat: 3.991418,
          lng: -73.771626,
          title: "ZONA DIGITAL LA PRADERA",
          icon: marker_ico,
          dir: "Barrio La Pradera, Calle 16 c Carrera 30",
        },
        {
          lat: 3.982838,
          lng: -73.758421,
          title: "ZONA DIGITAL LA UNION",
          icon: marker_ico,
          dir: "Barrio la Unión, Calle 10",
        },
        {
          lat: 3.985382,
          lng: -73.771271,
          title: "ZONA DIGITAL LAS ACACIAS",
          icon: marker_ico,
          dir: "Barrio las Acacias, Carrera 29 Calle 11A ",
        },
        {
          lat: 3.994813,
          lng: -73.779234,
          title: "ZONA DIGITAL LAS COLINAS",
          icon: marker_ico,
          dir: "Barrio Colinas, Carrera 46 Calle 20",
        },
        {
          lat: 3.99552,
          lng: -73.769063,
          title: "ZONA DIGITAL VILLA LUCIA",
          icon: marker_ico,
          dir: "Barrio Villa Lucia, Carrera 28 Calle 15",
        },
        {
          lat: 3.991741,
          lng: -73.777907,
          title: "ZONA DIGITAL LLANOVERDE I",
          icon: marker_ico,
          dir: "Barrio Llano Verde, Carrera 42B Calle 17",
        },
        {
          lat: 3.991442,
          lng: -73.759323,
          title: "ZONA DIGITAL MANCERA",
          icon: marker_ico,
          dir: "Barrio Mancera, Carrera 16 A Calle 18 A ",
        },
        {
          lat: 3.995243,
          lng: -73.757973,
          title: "ZONA DIGITAL MORICHAL",
          icon: marker_ico,
          dir: "Barrio Morichal, Carrera 16 Calle 23",
        },
        {
          lat: 3.993158,
          lng: -73.775769,
          title: "ZONA DIGITAL NUEVO JERUSALEM",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 3.987062,
          lng: -73.773355,
          title: "ZONA DIGITAL NUTIVARA",
          icon: marker_ico,
          dir: "Barrio Nutivara, Carrera 31 Calle 13",
        },
        {
          lat: 3.98892,
          lng: -73.752539,
          title: "ZONA DIGITAL PALERMO",
          icon: marker_ico,
          dir: "Barrio Palermo, Carrera 8 Calle 17",
        },
        {
          lat: 3.988166,
          lng: -73.750512,
          title: "ZONA DIGITAL POPULAR",
          icon: marker_ico,
          dir: "Barrio Popular,  Carrera 5 Calle 16",
        },
        {
          lat: 3.985172,
          lng: -73.781233,
          title: "ZONA DIGITAL PRIMAVERA",
          icon: marker_ico,
          dir: "Barrio Primavera, Carrera 40 Calle 10B ",
        },
        {
          lat: 3.996807,
          lng: -73.764627,
          title: "ZONA DIGITAL TIZA",
          icon: marker_ico,
          dir: "Barrio Tiza, Carrera 25 Calle 21A",
        },
        {
          lat: 4.007363,
          lng: -73.768905,
          title: "ZONA DIGITAL VILLA MANUELA",
          icon: marker_ico,
          dir: "Barrio Villa Manuela, Carrera 35 Calle 27 C ",
        },
        {
          lat: 4.003624,
          lng: -73.77485,
          title: "ZONA DIGITAL VILLA AURORA",
          icon: marker_ico,
          dir: "Barrio Villa Aurora, Carrera 39 Diagonal 15",
        },
        {
          lat: 3.915535,
          lng: -73.684655,
          title: "ZONA DIGITAL PARQUE CHICHIMENE",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 3.896419,
          lng: -73.466905,
          title: "ZONA DIGITAL DINAMARCA C FUTBOL",
          icon: marker_ico,
          dir: "Dinamarca, Calle 2da B Carrera 4ta ",
        },
        {
          lat: 3.992704,
          lng: -73.747893,
          title: "ZONA DIGITAL PVD PLUS",
          icon: marker_ico,
          dir: "Barrio Popular Km 1 Vía Dinamarca - Acacias ",
        },
        {
          lat: 3.990972,
          lng: -73.756904,
          title: "ZONA DIGITAL VILLA OLIMPICA ENTRADA",
          icon: marker_ico,
          dir: "Carrera 14 Calle 18",
        },
        {
          lat: 3.899363,
          lng: -73.47004,
          title: "AD DINAMARCA",
          icon: marker_ico,
          dir: "Dinamarca, Calle 6ta ",
        },
        {
          lat: 3.91516,
          lng: -73.683913,
          title: "AD CHICHIMENE",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 3.991899,
          lng: -73.783614,
          title: "ZONA DIGITAL PVD TRADICIONAL",
          icon: marker_ico,
          dir: "Barrio Colinas, Carrera 49 Calle 14",
        },
        {
          lat: 3.985136,
          lng: -73.758526,
          title: "ZONA DIGITAL CASA DE LA CULTURA",
          icon: marker_ico,
          dir: "Barrio Centro, Carrera 15 Calle 13",
        },
        {
          lat: 4.041369,
          lng: -73.78609,
          title: "ZONA DIGITAL VEREDA LA PRADERA",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 4.015451,
          lng: -73.78196,
          title: "ZONA DIGITAL VEREDA EL DIAMANTE",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 4.017002,
          lng: -73.668197,
          title: "ZONA DIGITAL VEREDA LAS MARGARITAS",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 3.99102,
          lng: -73.767875,
          title: "ZONA DIGITAL HOSPITAL MUNICIPAL DE ACACIAS",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 4.005664,
          lng: -73.793144,
          title: "ZONA DIGITAL VEREDA LAS BLANCAS",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 3.957649,
          lng: -73.8127,
          title: "ZONA DIGITAL VEREDA ALTO ACACIAS",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 4.045578,
          lng: -73.77829,
          title: "ZONA DIGITAL PARQUE GUAYURIBA",
          icon: marker_ico,
          dir: "No registra",
        },
        {
          lat: 3.987479,
          lng: -73.764786,
          title: "ZONA DIGITAL SEDE SENA",
          icon: marker_ico,
          dir: "Barrio Cooperativo, Calle 4 # 21 - 32 ",
        },
        {
          lat: 3.98555,
          lng: -73.780203,
          title: "ZONA DIGITAL COLEGIO TURBIA CANCHAS",
          icon: marker_ico,
          dir: "Barrio Independencia,  Carrera 38 Calle 10B ",
        },
        // Incluye aquí todas las otras ubicaciones
      ],
      markers: [],
      allMarkers: [],
      search: "",
      errorMessage: "",
      isSearchValid: false,
    };
  },
  mounted() {
    this.initMap();
    document.addEventListener('click', this.handleClickOutside);
    // Solicitar la ubicación actual tan pronto como se monte el componente
    this.getCurrentLocation();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    initMap() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 3.9916843, lng: -73.7764501 },
      zoom: 15,
    });
    this.infoWindow = new google.maps.InfoWindow();
    this.directionsService = new google.maps.DirectionsService();
    // Inicializar directionsRenderer aquí
    this.directionsRenderer = new google.maps.DirectionsRenderer();
    this.directionsRenderer.setMap(this.map); // Asignar el mapa al directionsRenderer
    this.addMarkers();
  },
    addMarkers() {
    this.locations.forEach((location) => {
      const marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: this.map,
        title: location.title,
        icon: {
          url: location.icon,
          scaledSize: new google.maps.Size(48, 48),
          anchor: new google.maps.Point(24, 24),
        },
      });
      marker.addListener("click", () => {
        this.infoWindow.setContent(`
          <div>
            <strong>${location.title}</strong><br/>
            Dirección: ${location.dir}<br/>
            <button id="routeButton" class='btn-search my-1' ${this.currentLocationAvailable ? '' : 'disabled'}>
              ¿Cómo llegar?
            </button>
          </div>
        `);
        this.infoWindow.open(this.map, marker);

        google.maps.event.addListener(this.infoWindow, 'domready', () => {
          const routeButton = document.getElementById("routeButton");
          if (routeButton) {
            routeButton.addEventListener("click", () => {
              this.showRoute(location.lat, location.lng);
            });
          }
        });

      });
      this.markers.push(marker);
    });
  },
    resetMapAndClearSearch() {
      this.initMap();
      this.search = "";
    },
    resetMap() {
      const mapElement = document.getElementById("map");
      while (mapElement.firstChild) {
        mapElement.removeChild(mapElement.firstChild);
      }
      this.map = new google.maps.Map(mapElement, {
        center: { lat: 3.9916843, lng: -73.7764501 },
        zoom: 15,
      });
      this.infoWindow = new google.maps.InfoWindow();
      this.directionsRenderers = [];
    },
    findLocation() {
      const fullAddress = `${this.search}, Acacias Meta Colombia`;
      axios
        .get(`https://maps.googleapis.com/maps/api/geocode/json`, {
          params: {
            address: fullAddress,
            key: GOOGLE_MAPS_API_KEY,
          },
        })
        .then((response) => {
          if (response.data.status === "OK" && response.data.results.length > 0) {
            const location = response.data.results[0].geometry.location;
            this.resetMap();
            this.map.setCenter(location);
            this.map.setZoom(15);
            this.addCurrentLocationMarker(location);
            this.findClosestLocations(location);
          } else {
            alert("No se encontraron resultados para la dirección ingresada.");
          }
        })
        .catch((error) => {
          console.error("Error durante la geocodificación:", error);
          alert("Geocodificación fallida: " + error.message);
        });
    },
    addCurrentLocationMarker(location) {
      if (this.currentMarker) {
        this.currentMarker.setMap(null);
      }
      this.currentMarker = new google.maps.Marker({
        position: location,
        map: this.map,
        icon: {
          url: marker_here_ico,
          scaledSize: new google.maps.Size(48, 48),
          anchor: new google.maps.Point(24, 24),
        },
      });
      this.markers.push(this.currentMarker);
      this.infoWindow.setContent("<div style='width: 105px;'><strong>Estás aquí</strong></div>");
      this.infoWindow.open(this.map, this.currentMarker);
    },
    findClosestLocations(location) {
      if (!google.maps.geometry) {
        console.error("La biblioteca de geometría no está disponible.");
        alert("La biblioteca de geometría no está disponible.");
        return;
      }
      const distances = this.locations.map((loc) => ({
        ...loc,
        distance: google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(location.lat, location.lng),
          new google.maps.LatLng(loc.lat, loc.lng)
        ),
      }));
      const closestLocations = distances
        .sort((a, b) => a.distance - b.distance)
        .slice(0, 2);
      closestLocations.forEach((location) => {
        const marker = new google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: this.map,
          title: location.title,
          icon: {
            url: location.icon,
            scaledSize: new google.maps.Size(48, 48),
            anchor: new google.maps.Point(24, 24),
          },
        });
        marker.addListener("click", () => {
          this.infoWindow.setContent(`
            <div>
              <strong>${location.title}</strong><br/>
              Dirección: ${location.dir}<br/>
              <button id="routeButton" class='btn-search my-1'>¿Cómo llegar?</button>
            </div>
          `);
          this.infoWindow.open(this.map, marker);
          // Esperar a que el contenido de infoWindow se haya renderizado completamente
          google.maps.event.addListener(this.infoWindow, 'domready', () => {
            document.getElementById("routeButton").addEventListener("click", () => {
              this.showRoute(location.lat, location.lng);
            });
          });
        });
        this.markers.push(marker);
      });
      this.plotRoutes(location, closestLocations);
    },
    plotRoutes(origin, destinations) {
      destinations.forEach((destination, index) => {
        const request = {
          origin: new google.maps.LatLng(origin.lat, origin.lng),
          destination: new google.maps.LatLng(destination.lat, destination.lng),
          travelMode: "DRIVING",
        };
        this.directionsService.route(request, (result, status) => {
          if (status === "OK") {
            const renderer = new google.maps.DirectionsRenderer({
              map: this.map,
              polylineOptions: {
                strokeColor: this.routeColors[index % this.routeColors.length],
                strokeWeight: 5,
              },
            });
            renderer.setDirections(result);
            this.directionsRenderers.push(renderer);
          } else {
            console.error("Error al trazar la ruta:", status);
            alert("Error al trazar la ruta: " + status);
          }
        });
      });
    },
    showRoute(destinationLat, destinationLng) {
      this.resetMapAndClearSearch();
      if (this.currentMarker) {
        const origin = this.currentMarker.getPosition();
        const destination = new google.maps.LatLng(destinationLat, destinationLng);
        const request = {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        };
        this.directionsService.route(request, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            // Colorear la ruta de verde
            this.directionsRenderer.setDirections(result);
            this.directionsRenderer.setOptions({ polylineOptions: { strokeColor: 'red' } });

            // Mostrar el pop-up en la ubicación actual
            this.infoWindow.setContent("<div style='width: 105px;'><strong>Estás aquí</strong></div>");
            this.infoWindow.open(this.map, this.currentMarker);
          } else {
            console.error("Error al trazar la ruta:", status);
            alert("Error al trazar la ruta: " + status);
          }
        });
      } else {
        alert("No se ha determinado la ubicación actual.");
      }
    },
    validate() {
      if (!this.search) {
        this.errorMessage = "Debes agregar una dirección";
        this.isSearchValid = false;
      } else {
        this.errorMessage = "";
        this.isSearchValid = true;
      }
    },
    markNearestRoute() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.resetMap();
            this.map.setCenter(location);
            this.map.setZoom(15);
            this.addCurrentLocationMarker(location);
            this.findClosestLocations(location);
            // Habilitar la ubicación actual
            this.currentLocationAvailable = true;
          },
          (error) => {
            console.error("Error al obtener la ubicación del usuario:", error);
            alert("No se pudo obtener la ubicación del usuario.");
          }
        );
      } else {
        console.error("El navegador no soporta la geolocalización.");
        alert("El navegador no soporta la geolocalización.");
      }
    },

    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.currentMarker = new google.maps.Marker({
              position: location,
              map: this.map,
              icon: {
                url: marker_here_ico,
                scaledSize: new google.maps.Size(48, 48),
                anchor: new google.maps.Point(24, 24),
              },
            });
            // Habilitar la ubicación actual
            this.currentLocationAvailable = true;
            
          },
          (error) => {
            console.error("Error al obtener la ubicación del usuario:", error);
            alert("No se pudo obtener la ubicación del usuario.");
          }
        );
      } else {
        console.error("El navegador no soporta la geolocalización.");
        alert("El navegador no soporta la geolocalización.");
      }
    },
  }
  
};
</script>