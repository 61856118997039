<template>
  <MapaWifi/>
</template>

<script>
import MapaWifi from './components/MapaWifi.vue'

export default {
  name: 'App',
  components: {
    MapaWifi
  }
}
</script>

<style>

body{
  background: linear-gradient(-45deg, #b8b8b8, #004520, #4d4d4d, #004520);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

html, body, #map {
    min-height: 100%;
    height: 100%;
}
</style>
