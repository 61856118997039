import { createApp } from 'vue';
import App from './App.vue';
import { Loader } from "@googlemaps/js-api-loader";
import { GOOGLE_MAPS_API_KEY } from './config';

// Importar archivos CSS de Bootstrap y Bootstrap Icons
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const app = createApp(App);

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["geometry"] // Asegúrate de cargar la biblioteca de geometría
});

loader.load().then(() => {
  app.mount('#app');
});
